import {Box, Container, Grid, IconButton, Typography} from "@mui/material";
import logo from "../../assets/images/utils-logo-copy-2.svg";
import userIcon from "../../assets/images/icon-content-user.svg";
import {useNavigate} from "react-router-dom";
import InstallBanner from "../../pwa/InstallBanner";
import backgroundImage from "../../assets/images/bg-copy.svg"

const HomePageLayout = ({children}) => {
    const navigate = useNavigate()

    const handleClickProfilo = () => navigate('/user-profile')

    return (
        <Box component="main" sx={{
            backgroundImage: `url(${backgroundImage})`,
            height: {xs: "17vh", sm: "26vh"}
        }}>
            <Container sx={{
                display: "flex",
                flexDirection: "column"
            }}>
                <Grid container sx={{
                    justifyContent: "space-between",
                    marginTop: {xs: "5vh", sm: "14vh"}
                }}>
                    <Grid item>
                        <Typography variant="body2" color="primary.contrastText">Benvenuto in</Typography>
                        <img src={logo} alt=""/>
                    </Grid>
                    <IconButton sx={{
                        display: "flex",
                        backgroundColor: "primary.contrastText",
                        borderRadius: "50%",
                        alignSelf: "center",
                        padding: '6px'
                    }}
                                onClick={handleClickProfilo}>
                        <img src={userIcon} alt=""/>
                    </IconButton>
                </Grid>
                {children}
            </Container>
                <InstallBanner/>
        </Box>
    )
}

export default HomePageLayout;
