import {Backdrop, CircularProgress} from "@mui/material";

const BackdropSpinner = () => {
    return (
        <Backdrop open={true}>
            <CircularProgress color="primary" />
        </Backdrop>
    )
}

export default BackdropSpinner