import ServiceRequestService from "../../api/serviceRequestService";
import {
    SET_ACTIVE_SERVICE_REQUESTS,
    SET_AREAS,
    SET_CATEGORIES,
    SET_CLOSED_SERVICE_REQUESTS,
    SET_DETAILS, SET_LOADING_ACTIVE_SERVICE_REQUESTS
} from "./actionTypes";

export const getActiveServiceRequest = (msisdn) => {
    return dispatch => {
        dispatch({type: SET_LOADING_ACTIVE_SERVICE_REQUESTS, value: true})
        ServiceRequestService.getActiveServiceRequest(msisdn)
            .then((res) => {
                dispatch({
                    type: SET_ACTIVE_SERVICE_REQUESTS,
                    data: res.data
                })
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                dispatch({type: SET_LOADING_ACTIVE_SERVICE_REQUESTS, value: false})
            })
    }
}

export const getClosedServiceRequest = (msisdn) => {
    return dispatch => {
        ServiceRequestService.getClosedServiceRequest(msisdn)
            .then((res) => {
                dispatch({
                    type: SET_CLOSED_SERVICE_REQUESTS,
                    data: res.data
                })
            })
            .catch((e) => {
                console.log(e)
            })
    }
}

export const getCategories = (msisdn, screenCode) => {
    return dispatch => {
        ServiceRequestService.getCategories(msisdn, screenCode)
            .then(res => {
                dispatch({
                    type: SET_CATEGORIES,
                    data: res.data
                })
            })
            .catch(e => {
                console.log(e)
            })
    }
}

export const getAreas = (msisdn, categoryId, screenCode) => {
    return dispatch => {
        ServiceRequestService.getAreas(msisdn, categoryId, screenCode)
            .then(res => {
                dispatch({
                    type: SET_AREAS,
                    data: res.data
                })
            })
            .catch(e => {
                console.log(e)
            })
    }
}

export const getDetails = (msisdn, categoryId, areaId, screenCode) => {
    return dispatch => {
        ServiceRequestService.getDetails(msisdn, categoryId, areaId, screenCode)
            .then(res => {
                dispatch({
                    type: SET_DETAILS,
                    data: res.data
                })
            })
            .catch(e => {
                console.log(e)
            })
    }
}
