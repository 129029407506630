import {Navigate, Route, Routes} from "react-router-dom";
import RouteWrapper from "../shared/routing/route-wrapper/RouteWrapper";
import {routePaths} from "../utils/constants";
import getRoutes from "../shared/routing/routes";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as actions from '../store/actions';

const App = () => {
    const userDetails = useSelector(state => state.authReducer.userDetails)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.getUserDetails());
    }, [dispatch])

    return (
        <Routes>
            {getRoutes(userDetails).map(r => (
                <Route
                    path={r.path}
                    key={r.path}
                    element={
                        <RouteWrapper component={r.component}
                                      layout={r.layout}
                                      canSeeRoute={r.canSeeRoute}
                                      redirectTo={r.redirectTo}
                        />
                    }
                />
            ))}
            <Route
                path="*"
                element={<Navigate to={routePaths.AUTH}/>}
            />
        </Routes>
    );
}

export default App;
