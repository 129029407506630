export const routePaths = {
    AUTH: "/auth",
    HOME_PAGE: "/",
    SIM_DETAILS: "/dettaglio-sim",
    DETTAGLIO_OFFERTA: "/dettaglio-offerta",
    ASSOCIAZIONE_SIM: "/associazione-sim",
    USER_PROFILE: "/user-profile",
    NEW_REQUEST: "/assistenza",
    RESET_PASSWORD: "/reset-password"
}

export const authForms = {
    DECISION: "decision",
    INSERT_EMAIL: "insertEmail",
    INSERT_CODE: "insertCode",
    CREATE_PASSWORD: "createPassword",
    LOGIN_PASSWORD_OK: "loginPasswordOk",
    LOGIN_FINAL: "loginFinal"
}

export const dettaglioScreens = {
    DETTAGLIO_SIM_HOME: "dettaglioSimHome",
    CHANGE_PASSWORD: "changePassword",
    CHANGE_PASSWORD_SUCCESS: "changePasswordSuccess",
    VIEW_PASSWORD: "viewPassword"
}

export const associazioneScreens = {
    NUMERO_SIM: "numeroSim",
    ASSOCIAZIONE_CODICE: "associazioneCodice",
    ASSOCIAZIONE_SUCCESS: "associazioneSuccess"
}

export const profileScreens = {
    PROFILO_HOME: "profiloHome",
    CHANGE_PASSWORD: "changePassword",
    CHANGE_PASSWORD_SUCCESS: "changePasswordSuccess",
    OPEN_GUIDE: "openGuide",
    //TODO: Add scarica (download massivo password apn)
    //TODO: Add aggiungi nuova sim
}

export const requestAssistanceScreens = {
    ASSISTENZA_HOME: "assistenzaHome",
    OPEN_REQUEST: "openRequest",
    OPEN_REQUEST_CONFIRMATION: "openRequestConfirmation"
}

export const storage = {
    TOKEN: "token",
    USER_DETAILS: 'user-details',
    SIM_DETAILS: 'sim-details',
    SIM_SELECTED_ROW: 'sim-selected-row'
}

//Screen Codes for Service Requests
export const E5_01_B_03 = "E5.01.B.03";
