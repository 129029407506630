import {storage} from "../../utils/constants";
import {updateObject} from "../../utils/utils";

export const simDetailsInitialState = {
    common: {
        loading: false,
        creditoOrCanoneLabel: '',
        creditoOrCanoneValue: '',
        serviceType: '',
        piano: '',
        prossimoAddebito: '',
        msisdn: '',
        iccId: '',
        serviceRequestsDtoList: [],
        tipologiaWallet: '',
    },
    tech: {
        loading: false,
        puk1: '',
        tipoApn: '',
        nomeApn: '',
        showClasseDiAbilitazioneVoce: false,
        classeDiAbilitazioneVoce: '',
        showAllowedNumbers: false,
        allowedNumbers: [],
        showBlockedNumbers: false,
        blockedNumbers: [],
        classeDiAbilitazioneDati: '',
        apnPassword: '',
        apnUsername: '',
        showPanel: true,
        tipoServizio: ''
    },
    com: {
        loading: false,
        opzioniGettoniList: [],
        creditoItemList: [],
        piano: '',
    }
}

export const selectedSimInitialState = {
    msisdn: '',
    encryptedMsisdn: '',
    companyId: '',
    state: ''
}

export const getSimDetailsInitialState = sessionStorage.getItem(storage.SIM_DETAILS) ?
    updateObject(simDetailsInitialState, {details: JSON.parse(sessionStorage.getItem(storage.SIM_DETAILS))})
    : simDetailsInitialState

export const reportInitialState = {
    id: '',
    loading: false
}

export const changeApnPasswordInitialState = {
    loading: false,
    error: false
}

export const apnPasswordInitialState = {
    loading: false,
    value: ""
}

export const eSimDetailsInitialState = {
    data: {
        esimAbilitata: "",
        numeroMassimo: "",
        profiloESim: "",
        eid: "",
        eidTooltip: "",
        numeroMassimoTooltip: "",
        profiloESimTooltip: "",
        esimAbilitataTooltip: ""
    },
    loading: false,
}