import {lazy} from "react";
import {routePaths} from "../../utils/constants";
import AuthLayout from "../../layouts/auth/AuthLayout";
import HomePageLayout from "../../layouts/home-page/HomePageLayout";
import DettaglioSimLayout from "../../layouts/sim-details/DettaglioSimLayout";

const Auth = lazy(() => import('../../components/auth/Auth'));
const HomePage = lazy(() => import('../../components/home-page/HomePage'));
const SimDetails = lazy(() => import('../../components/sim-details/DettaglioSim'));
const AssociazioneSim = lazy(() => import('../../components/associazione-sim/AssociazioneSim'));
const UserProfile = lazy(() => import('../../components/user-profile/UserProfile'));
const RequestAssistance = lazy(() => import('../../components/richiedi-assistenza/RichiediAssistenza'));
const DettaglioOfferta = lazy(() => import('../../components/dettaglio-offerta/DettaglioOfferta'));
const ResetPassword = lazy(() => import('../../components/reset-password/ResetPassword'));

const getRoutes = (userDetails) => [
    {
        path: routePaths.AUTH,
        component: Auth,
        layout: AuthLayout,
        canSeeRoute: !userDetails.email.trim(),
        redirectTo: routePaths.HOME_PAGE
    },
    {
        path: routePaths.HOME_PAGE,
        component: HomePage,
        layout: HomePageLayout,
        canSeeRoute: userDetails.email.trim(),
        redirectTo: routePaths.AUTH
    },
    {
        path: routePaths.NEW_REQUEST,
        component: RequestAssistance,
        layout: DettaglioSimLayout,
        canSeeRoute: userDetails.email.trim(),
        redirectTo: routePaths.AUTH
    },
    {
        path: routePaths.SIM_DETAILS,
        component: SimDetails,
        layout: DettaglioSimLayout,
        canSeeRoute: userDetails.email.trim(),
        redirectTo: routePaths.AUTH
    },
    {
        path: routePaths.ASSOCIAZIONE_SIM,
        component: AssociazioneSim,
        layout: DettaglioSimLayout,
        canSeeRoute: userDetails.email.trim(),
        redirectTo: !routePaths.AUTH
    },
    {
        path: routePaths.USER_PROFILE,
        component: UserProfile,
        layout: DettaglioSimLayout,
        canSeeRoute: userDetails.email.trim(),
        redirectTo: routePaths.AUTH
    },
    {
        path: routePaths.DETTAGLIO_OFFERTA,
        component: DettaglioOfferta,
        layout: DettaglioSimLayout,
        canSeeRoute: userDetails.email.trim(),
        redirectTo: routePaths.AUTH
    },
    {
        path: routePaths.RESET_PASSWORD,
        component: ResetPassword,
        layout: AuthLayout,
        canSeeRoute: true,
        redirectTo: routePaths.AUTH
    }
]

export default getRoutes;
