import UserService from "../../api/userService";
import * as actionTypes from './actionTypes';
import {SET_SELECTED_SIM} from "../sim/actionTypes";
import {storage} from "../../utils/constants";

export const getUserDetails = () => {
    return (dispatch) => {
        UserService.getUserDetails().then((res) => {
            if (res) {
                console.log(res.data);
                if (res.data.responseEntity.body.sims.length && !sessionStorage.getItem(storage.SIM_SELECTED_ROW)) {
                    dispatch({type: SET_SELECTED_SIM, payload: res.data.responseEntity.body.sims[0]})
                }
                dispatch({type: actionTypes.GET_USER_DETAILS_SUCCESS, payload: res.data.responseEntity.body})
            }
        })
    }
}
