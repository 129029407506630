import {API_DRIVER} from "../configs/axios";

const SimDetailsService = {
    getSimCommonDetails: (msisdn, cancelToken) => {
        return API_DRIVER.get(`/sim-details/common/msisdn/${msisdn}`, {
            cancelToken: cancelToken
        })
    },
    getSimTechDetails: (msisdn, cancelToken) => {
        return API_DRIVER.get(`/sim-details/tech/msisdn/${msisdn}`, {
            cancelToken: cancelToken
        })
    },
    getSimComDetails: (msisdn, cancelToken) => {
        return API_DRIVER.get(`/sim-details/com/msisdn/${msisdn}`, {
            cancelToken: cancelToken
        })
    },
    getReportId: (msisdn) => {
        return API_DRIVER.get(`/sim-details/report/msisdn/${msisdn}`)
    }
}
export default SimDetailsService;
