import * as actionTypes from "./actionTypes";
import {updateObject} from "../../utils/utils";
import {initialTableData} from "./helper";

const initialState = {
    activeServiceRequests: initialTableData,
    closedServiceRequests: initialTableData,
    categories: [],
    areas: [],
    details: []
}

const setActiveServiceRequests = (state, action) => {
    return updateObject(state, {activeServiceRequests: action.data})
}

const setClosedServiceRequests = (state, action) => {
    return updateObject(state, {closedServiceRequests: action.data})
}

const setCategories = (state, action) => {
    return updateObject(state, {categories: action.data})
}

const setAreas = (state, action) => {
    return updateObject(state, {areas: action.data})
}

const setDetails = (state, action) => {
    return updateObject(state, {details: action.data})
}

const setLoadingActiveServiceRequests = (state, action) => {
    return updateObject(state, {activeServiceRequests: updateObject(state.activeServiceRequests, {loading: action.value})})
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_SERVICE_REQUESTS:
            return setActiveServiceRequests(state, action);
        case actionTypes.SET_CLOSED_SERVICE_REQUESTS:
            return setClosedServiceRequests(state, action);
        case actionTypes.SET_CATEGORIES:
            return setCategories(state, action);
        case actionTypes.SET_AREAS:
            return setAreas(state, action);
        case actionTypes.SET_DETAILS:
            return setDetails(state, action);
        case actionTypes.SET_LOADING_ACTIVE_SERVICE_REQUESTS:
            return setLoadingActiveServiceRequests(state, action);
        default:
            return state;
    }
}
