import {API_DRIVER} from "../configs/axios";

const UserService = {
    getUserDetails: () => {
        return API_DRIVER.get("/user/user-accounts/me")
    },
    changePassword: (newPassword, currentPassword) => {
        const body = {
            newPassword: newPassword,
            currentPassword: currentPassword
        }
        return API_DRIVER.patch("/user/changePassword", body)
    }
}

export default UserService;
