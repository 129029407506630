export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};


export const safeStringFormat = (value) => {
    return value ?? ""
}

export const stringAndDashFormat = (value) => {
    return value ? value.length ? value : "-" : "-"
}

export const encodePlusSign = (param) => {
    return param.replace("+", "%2B")
}
