import {Box, Container, Paper} from "@mui/material";
import logo from '../../assets/images/utils-logo-copy-2.svg';

const AuthLayout = ({children}) => {
    return (
        <Box component="main" sx={{
            backgroundImage: "linear-gradient(237deg, #ffb436, #fcc300)",
            height: '100vh',
            backgroundPositionX: "center",
            backgroundPositionY: "center"
        }}>
            <Box position="absolute" left="50%" top="3em" sx={{transform: "translate(-50%)"}}>
                <img src={logo} alt="logo"/>
            </Box>
            <Container maxWidth="sm" sx={{
                display: "flex",
                justifyContent: "center",
                height: "100%"
            }}>
                <Paper elevation={3} sx={{
                    padding: "2em 3em",
                    textAlign: "center",
                    width: "100%",
                    alignSelf: "center"
                }}>
                    {children}
                </Paper>
            </Container>
        </Box>
    )
}

export default AuthLayout;
