import axios from 'axios';
import {store} from "../store";
import {RESET_USER_DETAILS} from "../store/auth/actionTypes";

const getBaseUrl = () => {
    const host = window.location.hostname.toLowerCase();
    // const port = window.location.port
    const protocol = window.location.protocol

    if (host.includes("localhost")) {
        return `${protocol}//${host}:8080`;
    }
    return `${protocol}//${host}:8443`;
}

export const API_DRIVER = axios.create(
    {
        baseURL: getBaseUrl(),
        responseType: "json",
        withCredentials: true,
        headers: {
            "Access-Control-Allow-Origin": "*"
        }
    }
)

// API_DRIVER.interceptors.request.use(
//     config => {
//         const token = sessionStorage.getItem(TOKEN_NAME);
//         if (token) {
//             config.headers.Authorization = `${token}`;
//         } else {
//             //console.log("Unauthorized!");
//         }
//         return config;
//     },
//     error => Promise.reject(error)
// );
//
API_DRIVER.interceptors.response.use(
    response => {
        if (response.headers['content-type'] && response.headers['content-type'].includes('text/html')) {
            store.dispatch({type: RESET_USER_DETAILS})
            return
        }
        return response
    },
    error => {
        if (error?.response?.status === 401) {
            store.dispatch({type: RESET_USER_DETAILS})
        }
        return Promise.reject(error);
    }
);
