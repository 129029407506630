import * as actionTypes from './actionTypes';
import {initialUserDetailsState} from "./helpers";
import {updateObject} from "../../utils/utils";
import {storage} from "../../utils/constants";

const initialState = {
    userDetails: JSON.parse(sessionStorage.getItem(storage.USER_DETAILS)) ?? initialUserDetailsState
}

const setUserDetails = (state, action) => {
    sessionStorage.setItem(storage.USER_DETAILS, JSON.stringify(action.payload))
    return updateObject(state, {userDetails: action.payload})
}

const resetUserDetails = (state) => {
    sessionStorage.removeItem(storage.USER_DETAILS)
    return updateObject(state, {userDetails: initialUserDetailsState})
}

const addAssociatedSimToList = (state, action) => {
    const updatedObj = updateObject(state, {userDetails: updateObject(state.userDetails, {sims: [...state.userDetails.sims, action.payload]})})
    sessionStorage.setItem(storage.USER_DETAILS, JSON.stringify(updatedObj))
    return updatedObj;
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_DETAILS_SUCCESS:
            return setUserDetails(state, action);
        case actionTypes.RESET_USER_DETAILS:
            return resetUserDetails(state);
        case actionTypes.ASSOCIATE_NEW_SIM_SUCCESS:
            return addAssociatedSimToList(state, action);
        default:
            return state;
    }
}
