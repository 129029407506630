import {combineReducers} from "redux";
import {reducer as authReducer} from "./auth/reducer";
import {reducer as simReducer} from "./sim/reducer";
import {reducer as serviceRequestReducer} from "./serviceRequest/reducer";

const rootReducer = combineReducers({
    authReducer: authReducer,
    simReducer: simReducer,
    serviceRequestReducer: serviceRequestReducer
})

export default rootReducer;
