import {Suspense} from "react";
import BackdropSpinner from "../../../components/shared/backdrop-spinner/BackdropSpinner";
import {Navigate, useLocation} from "react-router-dom";

const RouteWrapper = ({component: Component, layout: Layout, canSeeRoute, redirectTo}) => {
    let location = useLocation();

    const baseComponent = (
        <Layout>
            <Suspense fallback={<BackdropSpinner/>}>
                <Component/>
            </Suspense>
        </Layout>
    )
    return (
        canSeeRoute ? baseComponent : <Navigate to={redirectTo} state={{from: location}}/>
    )
}

export default RouteWrapper;
