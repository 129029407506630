import {API_DRIVER} from "../configs/axios";

const SimService = {
    checkSIMEnrollment: (msisdn) => {
        const body = {
            msisdn: msisdn
        }
        return API_DRIVER.post('/sim/sim-enrollment-requests', body)
    },
    simAssign: (msisdn, otp) => {
        const body = {
            msisdn: msisdn,
            otp: otp
        }
        return API_DRIVER.post('/sim/sim-assign', body)
    },
    changePassword: (changePasswordDto) => {
        return API_DRIVER.post("/sim/change-password", changePasswordDto)
    },
    getApnPassword: (msisdn) => {
        return API_DRIVER.get(`/sim/${msisdn}/apn-password`)
    },
    fetchESimDetails: (iccid) => {
        return API_DRIVER.get(`/sim/eSim/${iccid}`)
    }
}
export default SimService;
