import {Box, Container} from "@mui/material";
import iconBigOffer from '../../assets/images/icon-big-offer-88-px.svg'

const DettaglioSimLayout = ({ children }) => {
    return (
        <Box sx={{
            backgroundImage: `url(${iconBigOffer})`,
            backgroundRepeat: "no-repeat",
            minHeight:"100vh",
            backgroundSize: "cover",
            backgroundPosition: "center"
        }}>
            <Container >
                {children}
            </Container>
        </Box>
    )
}

export default DettaglioSimLayout;
