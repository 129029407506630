import * as actionTypes from './actionTypes';
import {
    apnPasswordInitialState,
    changeApnPasswordInitialState,
    eSimDetailsInitialState,
    getSimDetailsInitialState,
    reportInitialState,
    selectedSimInitialState
} from "./helpers";
import {updateObject} from "../../utils/utils";
import {storage} from "../../utils/constants";

const initialState = {
    simDetails: getSimDetailsInitialState,
    selectedSim: sessionStorage.getItem(storage.SIM_SELECTED_ROW) ?
        JSON.parse(sessionStorage.getItem(storage.SIM_SELECTED_ROW)) :
        selectedSimInitialState,
    report: reportInitialState,
    changeApnPassword: changeApnPasswordInitialState,
    apnPassword: apnPasswordInitialState,
    eSimDetails: eSimDetailsInitialState,
}

const setLoadingSimDetails = (state, action) => {
    return updateObject(state, {
        simDetails: updateObject(state.simDetails, {
            [action.key]:
                updateObject(state.simDetails[action.key], {loading: action.payload})
        })
    })
}

const setSimDetails = (state, action) => {
    const updatedObj = updateObject(state, {
        simDetails: updateObject(state.simDetails, {
            [action.key]:
                updateObject(state.simDetails[action.key], {...action.payload})
        })
    })
    sessionStorage.setItem(storage.SIM_DETAILS, JSON.stringify(updatedObj))
    return updatedObj
}

const setSelectedSim = (state, action) => {
    sessionStorage.setItem(storage.SIM_SELECTED_ROW, JSON.stringify(action.payload))
    return updateObject(state, {selectedSim: action.payload})
}

const setLoadingReportId = (state, action) => {
    return updateObject(state, {
        report: updateObject(state.report, {loading: action.payload})
    })
}

const setReportId = (state, action) => {
    return updateObject(state, {
        report: updateObject(state.report, {id: action.payload.reportId})
    })
}

const setChangeApnPasswordValue = (state, action) => {
    return updateObject(state, {changeApnPassword: updateObject(state.changeApnPassword, {[action.name]: action.value})})
}

const setApnPasswordValue = (state, action) => {
    return updateObject(state, {apnPassword: updateObject(state.apnPassword, {[action.name]: action.value})})
}

const setESimsDetails = (state, action) => {
    return updateObject(state, {
        eSimDetails: updateObject(state.eSimDetails, {
            [action.key]: action.value
        })
    })
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOADING_SIM_DETAILS:
            return setLoadingSimDetails(state, action);
        case actionTypes.GET_SIM_DETAILS_SUCCESS:
            return setSimDetails(state, action);
        case actionTypes.SET_SELECTED_SIM:
            return setSelectedSim(state, action);
        case actionTypes.SET_LOADING_REPORT_ID:
            return setLoadingReportId(state, action);
        case actionTypes.GET_REPORT_ID_SUCCESS:
            return setReportId(state, action);
        case actionTypes.SET_CHANGE_APN_PASSWORD_VALUE:
            return setChangeApnPasswordValue(state, action);
        case actionTypes.SET_APN_PASSWORD_VALUE:
            return setApnPasswordValue(state, action);
        case actionTypes.GET_E_SIM_DETAILS_AND_LOADING:
            return setESimsDetails(state, action)
        default:
            return state;
    }
}
