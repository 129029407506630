import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {theme} from "./shared/theme/theme";
import {BrowserRouter} from "react-router-dom";
import {store} from "./store";
import {Provider} from "react-redux";
import moment from "moment";
import {ToastContainer} from "react-toastify";
import {ToastCloseButton} from "./components/shared/toast/toast-close-button/ToastCloseButton";
import "./assets/styles/Toastify.css"

moment.locale('it');

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ToastContainer autoClose={4000}
                            hideProgressBar
                            closeButton={ToastCloseButton}/>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <App/>
                </ThemeProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
