import {API_DRIVER} from "../configs/axios";
import {encodePlusSign} from "../utils/utils";

const ServiceRequestService = {
    getActiveServiceRequest: (msisdn) => {
        return API_DRIVER.get(`/serviceRequest/aperte?encryptedMsisdn=${encodePlusSign(msisdn)}&page=0&size=100`)
    },
    getClosedServiceRequest: (msisdn) => {
        return API_DRIVER.get(`/serviceRequest/archiviate?encryptedMsisdn=${encodePlusSign(msisdn)}&page=0&size=100`)
    },
    getCategories: (msisdn, screenCode) => {
        return API_DRIVER.get(`/serviceRequest/categories?encryptedMsisdn=${encodePlusSign(msisdn)}&screenCode=${screenCode}`)
    },
    getAreas: (msisdn, categoryId, screenCode) => {
        return API_DRIVER.get(`/serviceRequest/areas?encryptedMsisdn=${encodePlusSign(msisdn)}&screenCode=${screenCode}&categoryId=${categoryId}`)
    },
    getDetails: (msisdn, categoryId, areaId, screenCode) => {
        return API_DRIVER.get(`/serviceRequest/dettaglio?encryptedMsisdn=${encodePlusSign(msisdn)}&screenCode=${screenCode}&categoryId=${categoryId}&areaId=${areaId}`)
    },
    submitProblem: (reportProblemDto) => {
        return API_DRIVER.post("/serviceRequest/reportProblem", reportProblemDto)
    }
}

export default ServiceRequestService;
