import {deferredPrompt} from "./installWorker";
import {useRef} from "react";
import {Box, Button, Typography, useMediaQuery, useTheme} from "@mui/material";
import closeIcon from "../assets/images/icon-navigation-close-24-px.svg";
import zIndex from "@mui/material/styles/zIndex";

const InstallBanner = () => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    let hideBanner = false

    const banner = useRef(null)

    const closeBanner = () => {
        banner.current.style.display = 'none';
    }

    if (window.matchMedia('(display-mode: standalone)').matches) {
        hideBanner = true
    }

    const installClick=async  ()=> {
        closeBanner();
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        const choiceResult = await deferredPrompt.userChoice
        if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
        } else {
            closeBanner();
            console.log('User dismissed the A2HS prompt');
        }
    }
        return (
            (hideBanner) || !smallScreen ? "" :
                <Box ref={banner} sx={{
                    position: "fixed",
                    width: "100%",
                    backgroundColor: "#fdc400",
                    textAlign: "center",
                    padding: "0.5em",
                    bottom: "0",
                    display: "flex",
                    alignItems: "center",
                    zIndex: zIndex.tooltip + 1
                }}>
                    <Box onClick={installClick} sx={{width: "100%", float: "left"}}>
                        <Typography sx={{
                            color: "#000",
                            fontSize: "0.7em",
                            textTransform: "uppercase"
                        }}>Aggiungi l’app alla schermata Home</Typography>
                    </Box>
                    <Button onClick={closeBanner} sx={{
                        border: "none",
                        backgroundColor: "transparent",
                    float: "right"
                }}><img src={closeIcon} alt=''/></Button>
            </Box>
    )
}

export default InstallBanner;
