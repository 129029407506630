export const colors ={
    primary: {
        light: '#ffd56c',
        main: '#fdc400',
        dark: '#f2a800',
        contrastText: '#fff'
    },
    secondary: {
        light: '#ffc063',
        main: '#ff9901',
        dark: '#e78a00'
    },
    tertiary: {
        light: '#78cdeb',
        main: '#4fc0e8',
        dark: '#42a1c3'
    },
    success: {
        main: '#8bbc5c'
    },
    error: {
        light: '#fcc7c3',
        main: '#f44336'
    },
    background: {
        default: '#f6f6f6'
    },
    font:{
        md: '#444',
        dark: '#212121',
        light: '#757575'
    },
    grey: {
        dark: '#3e4953',
        light: '#efeff5'
    }
}