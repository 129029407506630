import SimDetailsService from "../../api/simDetailsService";
import * as actionTypes from './actionTypes';
import {store} from "../index";
import axios from "axios";
import SimService from "../../api/simService";

let simCommonDetailsCancelToken = axios.CancelToken.source();
let simTechDetailsCancelToken = axios.CancelToken.source();
let simComDetailsCancelToken = axios.CancelToken.source();

export const fetchSimCommonDetails = (msisdn) => {
    const commonDetailsLoading = store.getState().simReducer.simDetails.common.loading
    return (dispatch) => {
        if (commonDetailsLoading) {
            simCommonDetailsCancelToken.cancel();
            simCommonDetailsCancelToken = axios.CancelToken.source();
        }
        dispatch({type: actionTypes.SET_LOADING_SIM_DETAILS, payload: true, key: 'common'})
        SimDetailsService.getSimCommonDetails(msisdn, simCommonDetailsCancelToken.token).then((res) => {
            dispatch({type: actionTypes.GET_SIM_DETAILS_SUCCESS, payload: res.data, key: 'common'})
            dispatch({type: actionTypes.SET_LOADING_SIM_DETAILS, payload: false, key: 'common'})
        }).catch((err) => {
            if (!axios.isCancel(err)) {
                dispatch({type: actionTypes.SET_LOADING_SIM_DETAILS, payload: false, key: 'common'})
                console.log(err.response)
            }
        })
    }
}

export const fetchSimTechDetails = (msisdn) => {
    const techDetailsLoading = store.getState().simReducer.simDetails.tech.loading
    return (dispatch) => {
        if (techDetailsLoading) {
            simTechDetailsCancelToken.cancel();
            simTechDetailsCancelToken = axios.CancelToken.source();
        }
        dispatch({type: actionTypes.SET_LOADING_SIM_DETAILS, payload: true, key: 'tech'})
        SimDetailsService.getSimTechDetails(msisdn, simTechDetailsCancelToken.token).then((res) => {
            dispatch({type: actionTypes.GET_SIM_DETAILS_SUCCESS, payload: res.data, key: 'tech'})
            dispatch({type: actionTypes.SET_LOADING_SIM_DETAILS, payload: false, key: 'tech'})
        }).catch((err) => {
            if (!axios.isCancel(err)) {
                dispatch({type: actionTypes.SET_LOADING_SIM_DETAILS, payload: false, key: 'tech'})
                console.log(err.response)
            }
        })
    }
}

export const fetchSimComDetails = (msisdn) => {
    const comDetailsLoading = store.getState().simReducer.simDetails.com.loading
    return (dispatch) => {
        if (comDetailsLoading) {
            simComDetailsCancelToken.cancel();
            simComDetailsCancelToken = axios.CancelToken.source();
        }
        dispatch({type: actionTypes.SET_LOADING_SIM_DETAILS, payload: true, key: 'com'})
        SimDetailsService.getSimComDetails(msisdn, simComDetailsCancelToken.token).then((res) => {
            dispatch({type: actionTypes.GET_SIM_DETAILS_SUCCESS, payload: res.data, key: 'com'})
            dispatch({type: actionTypes.SET_LOADING_SIM_DETAILS, payload: false, key: 'com'})
        }).catch((err) => {
            if (!axios.isCancel(err)) {
                dispatch({type: actionTypes.SET_LOADING_SIM_DETAILS, payload: false, key: 'com'})
                console.log(err.response)
            }
        })
    }
}

export const fetchReportId = (msisdn) => {
    return (dispatch) => {
        dispatch({type: actionTypes.SET_LOADING_REPORT_ID, payload: true})
        SimDetailsService.getReportId(msisdn).then((res) => {
            dispatch({type: actionTypes.GET_REPORT_ID_SUCCESS, payload: res.data})
        }).catch((err) => {
            console.log(err.response)
        }).finally(() => {
            dispatch({type: actionTypes.SET_LOADING_REPORT_ID, payload: false})
        })
    }
}

export const changePassword = (msisdn, userId, password) => {
    const changePasswordDto = {
        userId: userId,
        msisdn: msisdn,
        password: password
    }

    return dispatch => {
        dispatch({type: actionTypes.SET_CHANGE_APN_PASSWORD_VALUE, name: "loading", value: true})
        SimService.changePassword(changePasswordDto)
            .then(() => {
                dispatch({type: actionTypes.SET_CHANGE_APN_PASSWORD_VALUE, name: "error", value: false})
                console.log("Password modified successfully for SIM: " + msisdn)
            })
            .catch(e => {
                dispatch({type: actionTypes.SET_CHANGE_APN_PASSWORD_VALUE, name: "error", value: true})
                console.error(e.response)
            })
            .finally(() => {
                dispatch({type: actionTypes.SET_CHANGE_APN_PASSWORD_VALUE, name: "loading", value: false})
            })
    }
}

export const getApnPassword = (msisdn) => {
    return dispatch => {
        dispatch({type: actionTypes.SET_APN_PASSWORD_VALUE, name: "loading", value: true})
        SimService.getApnPassword(msisdn)
            .then(res => {
                dispatch({type: actionTypes.SET_APN_PASSWORD_VALUE, name: "value", value: res.data})
            })
            .catch(e => {
                console.error(e.response)
            })
            .finally(() => {
                dispatch({type: actionTypes.SET_APN_PASSWORD_VALUE, name: "loading", value: false})
            })
    }
}

export const getESimDetails = (iccid) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_E_SIM_DETAILS_AND_LOADING,
            key: "loading",
            value: true
        })
        SimService.fetchESimDetails(iccid)
            .then((res) => {
                dispatch({
                    type: actionTypes.GET_E_SIM_DETAILS_AND_LOADING,
                    value: res.data,
                    key: "data"
                })
            })
            .catch((e) => {
                console.error(e.response.data)
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.GET_E_SIM_DETAILS_AND_LOADING,
                    key: "loading",
                    value: false,
                })
            })
    }
}